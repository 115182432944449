require('../css/font.css');
require('../css/style.css');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {

    document.body.addEventListener('click', e => {

        if (e.target.classList.contains('faq-btn') || e.target.classList.contains('faq-title')) {
            toggleFaq(e.target, {
                collapseOthers: true
            });
        } else if (e.target.classList.contains('read-more')) {
            toggleReadMore(e.target, {
                parent: '.header-texts',
                area: '.slide-down',
                wrapper: '.slide-down-measure',
                text: 'self'
            });
        } else if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
        } else if (e.target.classList.contains('zoomable-img')) {

            const img = new Image();
            const currentSrc = e.target.src;
            const filePath = currentSrc.substring(0, currentSrc.lastIndexOf('.'));
            const fileExt = currentSrc.substring(currentSrc.lastIndexOf('.'));
            img.src = filePath + '-large' + fileExt;

            const closeBtn = document.createElement('span');
            closeBtn.classList.add('close-btn');

            const overlay = document.createElement('div');
            overlay.classList.add('zoomable-image-overlay');
            overlay.appendChild(img);
            overlay.appendChild(closeBtn);

            document.body.appendChild(overlay);

        } else if (e.target.classList.contains('zoomable-image-overlay')) {
            e.target.parentElement.removeChild(e.target);
        } else if (e.target.classList.contains('close-btn')) {
            e.target.parentElement.parentElement.removeChild(e.target.parentElement);
        }
    });

    window.addEventListener('resize', e => {
        const height = !isMobile() ? 'initial' : '0px';
        const mobileReadMoreAreas = Array.from(document.querySelectorAll('.m-slide-down'));
        mobileReadMoreAreas.forEach(area => area.style.height = height);
    });
}

function scrollTo(e) {

    e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });

    if (link.dataset.tab) {
        document.getElementById(link.dataset.tab).click();
    }
}

function toggleFaq(faqBtn, options = {}) {
    const faq = faqBtn.closest('.faq');
    if (faq.classList.contains('collapsed')) {

        if (Object(options).collapseOthers) {
            const wrapper = faqBtn.closest('.faq-wrapper');
            const faqs = wrapper.querySelectorAll('.faq');
            if (faqs.length) {
                faqs.forEach(item => {
                    item.classList.remove('expanded');
                    item.classList.add('collapsed');
                });
            }
        }

        faq.classList.remove('collapsed');
        faq.classList.add('expanded');
    } else {
        faq.classList.remove('expanded');
        faq.classList.add('collapsed');
    }
}

function toggleReadMore(clicked, options) {

    const toggleArea = clicked.closest(options.parent).querySelector(options.area);

    if (toggleArea.clientHeight) {
        toggleArea.style.height = 0;
        if (options.text) {
            const text = options.textCollapsed || 'Read More';
            options.text === 'self'
                ? clicked.textContent = text
                : clicked.querySelector(options.text).textContent = text;
        }
        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(0deg)';

        if (typeof options.onCollapsed === 'function') {
            options.onCollapsed.call(null, clicked, options);
        }
    } else {

        const wrapper = toggleArea.querySelector(options.wrapper || 'div');

        // Handle any nested read mores.
        // This will trigger when device is mobile and there is nested desktop read more.
        if (Array.isArray(options.nested)) {
            options.nested.forEach(selector => {
                const nestedAreas = wrapper.querySelectorAll(selector);
                if (nestedAreas.length) {
                    nestedAreas.forEach(nestedArea => nestedArea.style.height = 'auto');
                }
            });
        }

        toggleArea.style.height = wrapper.clientHeight + 'px';

        if (options.text) {
            const text = options.textExpanded || 'Collapse';
            options.text === 'self'
                ? clicked.textContent = text
                : clicked.querySelector(options.text).textContent = text;
        }

        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(180deg)';

        if (typeof options.onExpanded === 'function') {
            options.onExpanded.call(null, clicked, options);
        }
    }
}

function isMobile() {
    const detector = document.querySelector('.mobile-detector');
    const styles = window.getComputedStyle(detector);
    return styles.getPropertyValue('display') === 'none';
}